import React, { useState } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Video from "./views/Video";
import ExternalApi from "./views/ExternalApi";
import Training from "./views/Training";
import Dashboard from "./views/Dashboard";
import PaymentsPage from "./views/Payments";
import ProductsPage from "./views/Products";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";


initFontAwesome();

const App = () => {
  const [jwtToken, setJwtToken] = useState('asdf');

  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  const handleTokenChange = (newToken) => {
    setJwtToken(newToken)
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route path="/" exact render={() => <Home token={jwtToken} onTokenChange={handleTokenChange} />} />
            <Route path="/profile" render={() => <Profile token={jwtToken} onTokenChange={handleTokenChange} />} />
            <Route path="/external-api" render={() => <ExternalApi token={jwtToken} onTokenChange={handleTokenChange} />} />
            <Route path="/video" render={() => <Video jwtToken={jwtToken} onTokenChange={handleTokenChange} />} />
            <Route path="/training" render={() => <Training token={jwtToken} onTokenChange={handleTokenChange} />} />
            <Route path="/dashboard" render={() => <Dashboard token={jwtToken} onTokenChange={handleTokenChange} />} />
            <Route path="/payments" render={() => <PaymentsPage token={jwtToken} onTokenChange={handleTokenChange} />} />
            <Route path="/products" render={() => <ProductsPage token={jwtToken} onTokenChange={handleTokenChange} />} />

          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
