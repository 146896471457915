import React, { useState, useEffect, useRef } from 'react';
import Video from 'twilio-video';
import { ENDPOINT } from '../constants';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

const VideoRoom = ({jwtToken, onTokenChange}) => {
  console.log("Hello video room")
  console.log(jwtToken)
  const { user, getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState({
    token: null,
    error: null,
  });
  const [identity, setIdentity] = useState('');
  const [roomName, setRoomName] = useState('');
  const [token, setToken] = useState(null);
  const [rooms, setRooms] = useState([]);
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();

  useEffect(() => {
    
    // Send a GET request to the new endpoint we created
    console.log("getting rooms")
    const fetchRooms = async () => {
      try {
        const response = await fetch(`${ENDPOINT}/room`);
        if (!response.ok) {
          throw new Error('Failed to fetch rooms');
        }
        const data = await response.json();
        console.log(data)
        setRooms(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchRooms();
    
    
  }, []);

  const joinFlirtRoom = async () => {
    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`${ENDPOINT}/flirt/join`, {
        method:  'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to join room');
      }
      const data = await response.json();
      console.log('got data');
      console.log(data);
      setToken(data.token);
    } catch (error) {
      console.error("Error joining flirt room")
    }
  };

  const roomCreate = async () => {
    try {
      console.log("getting token")
      const accessToken = await getAccessTokenSilently();
      console.log(accessToken)
      
      const response = await fetch(`${ENDPOINT}/room/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${accessToken}`,
        },
        body: JSON.stringify({ roomName }),
      });

      const data = await response.json();
      setToken(data.token);
    } catch (error) {
      console.error('Error getting access token:', error);
    }
  };

  const roomJoin = async () => {
    try {
      console.log("getting token")
      const accessToken = await getAccessTokenSilently();
      console.log(accessToken)
      
      const response = await fetch(`${ENDPOINT}/room/join`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${accessToken}`,
        },
        body: JSON.stringify({ roomName }),
      });

      const data = await response.json();
      setToken(data.token);
    } catch (error) {
      console.error('Error getting access token:', error);
    }
  };
  

  const joinRoom = () => {
    Video.connect(token, { name: roomName }).then(room => {
      console.log(`Connected to Room: ${room.name}`);

      // Attach local video
      Video.createLocalVideoTrack().then(track => {
        localVideoRef.current.appendChild(track.attach());

        // Attach remote participant video
        const addParticipant = (participant) => {
          console.log(`Participant "${participant.identity}" connected`);

          participant.tracks.forEach(publication => {
            if (publication.isSubscribed) {
              const track = publication.track;
              remoteVideoRef.current.appendChild(track.attach());
            }
          });

          participant.on('trackSubscribed', track => {
            remoteVideoRef.current.appendChild(track.attach());
          });
        };

        room.participants.forEach(addParticipant);
        room.on('participantConnected', addParticipant);

        // Handle room disconnection
        room.on('participantDisconnected', participant => {
          console.log(`Participant "${participant.identity}" disconnected`);
          participant.tracks.forEach(publication => {
            if (publication.track) {
              publication.track.detach().forEach(element => element.remove());
            }
          });
        });

        room.on('disconnected', room => {
          room.localParticipant.tracks.forEach(publication => {
            const attachedElements = publication.track.detach();
            attachedElements.forEach(element => element.remove());
          });
        });
      });
    }); // Add the missing closing parenthesis here
  };

  useEffect(() => {
    if (token) {
      joinRoom();
    }
  }, [token]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    roomCreate();
  };

  const handleJoinRoom = (room_id) => {
    setRoomName(room_id);
    roomJoin(); // call this to join the room
  };

  const handleJoinFlirtRoom = (e) => {
    e.preventDefault();
    joinFlirtRoom();
  }

  return (
    <div>
      <h1>Video Chat App</h1>
      {!token && (
        <form onSubmit={handleFormSubmit}>
          <button type="submit">Create New Room</button>
        </form>
      )}

      {!token && (
        <form onSubmit={handleJoinFlirtRoom}>
          <button type="submit">Join/Create Flirt Room</button>
        </form>
      )}
      {!token && (
        <div>
          <h2>List of Rooms</h2>
          <ul>
            {rooms.map((room) => (
            <li key={room.id}>
              {room.room_id}
              <button onClick={() => handleJoinRoom(room.room_id)}>
                Join Existing Room
              </button>
            </li>
          ))}
          </ul>
        </div>
      )}

      <div ref={localVideoRef} />
      <div ref={remoteVideoRef} />
    </div>
  );
};

export default VideoRoom;
