import CheckoutForm from "../components/Checkout";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ENDPOINT } from '../constants';

export const PaymentsPage = ({ token, onTokenChange }) => {
    const [stripePromise, setStripePromise] = useState(null);
    console.log("Dashboard token")
    console.log(token)

    useEffect(() => {
        const fetchStripe = async () => {
            const stripe = await loadStripe('pk_test_CGXQpDDikj81wtvL957syTja');
            setStripePromise(stripe);
        };

        fetchStripe();
    }, []);
    return (
        <Container className="mb-5">
            <Elements stripe={stripePromise}>
                <CheckoutForm />
            </Elements>
        </Container>
    );

};

export default withAuthenticationRequired(PaymentsPage, {
    onRedirecting: () => <Loading />,
});
